import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationService } from '../../services/navigation.service';
import { ObjUtils } from '../../services/obj.utils';
import { ErrorModalDialogData } from './error-modal-dialog.data';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent {

  constructor(
    public dialogRef: MatDialogRef<ErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ErrorModalDialogData,
    protected sanitizer: DomSanitizer,
    protected iconRegistry: MatIconRegistry,
    private navigationService: NavigationService,
  ) {
    iconRegistry.addSvgIcon('lightning-filled', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/lightning-filled.svg'));
  }

  public onClsBtnClick(): void {
    if (!ObjUtils.isNullOrUndefinedOrEmpty(this.data.link)) {
      this.navigationService.navigateWithQueryParamsAndFragment([this.data.link], this.data.viewMode, null, null, this.data.fragment);
    }
    this.dialogRef.close();
  }
}
