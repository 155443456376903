import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app-state';
import { User } from 'app/shared/common/models/user';
import { TokenService } from 'app/auth/services/token.service';
import { loginFailureResponse, logoutAction, tokenRefreshSuccessResponse } from 'app/auth/state/auth.actions';
import { loggedInUserSelector } from 'app/auth/state/auth.reducers';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { EnvironmentService } from '../services/env.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ServiceOutageDialogComponent } from '../components/service-outage-dialog/service-outage.dialog.component';

@Injectable({
  providedIn: 'root'
})
export class HttpConfigInterceptor implements HttpInterceptor {

  public loggedInUser: User;

  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private serviceOutageRead = false;

  constructor(
    private tokenService: TokenService,
    private dialog: MatDialog,
    private store: Store<AppState>,
    private logger: NGXLogger) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(loggedInUserSelector).pipe(
      take(1),
      switchMap(loggedInUser => {
        request = this.addApiKey(request);

        if (loggedInUser?.tokenData) {
          request = this.addAccessToken(request, loggedInUser.tokenData.access_token);
        }

        return next.handle(request).pipe(
          catchError(error => {
            if (error instanceof HttpErrorResponse) {
              if (error.status === 401 && error.error.message === 'The incoming token has expired') {
                return this.handle401Error(request, next);
              } else if (error.status === 502) {
                if (!this.serviceOutageRead) {
                  this.openServiceOutageDialog(error?.error?.error);
                  this.serviceOutageRead = true;
                }
                return throwError(error);
              }
            }
            return throwError(error);
          }),
          tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.logger.trace('event--->>>', event);
            }
          })
        );
      })
    );
  }

  private addAccessToken(request: HttpRequest<any>, accessToken: string): HttpRequest<any> {
    // skip adding the Authorization Bearer header for these endpoints, because they are LION!! endpoints ffs
    // and obviously Dicon doesn't have LiOn tokens.
    if (this.shouldSkipAddingToken(request.url)) {
      return request;
    }

    return accessToken
      ? request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + accessToken) })
      : request;
  }

  private addApiKey(request: HttpRequest<any>): HttpRequest<any> {
    return !request.headers.has('x-api-key')
      ? request.clone({ headers: request.headers.set('x-api-key', EnvironmentService.getEnvironment().xApiKey) })
      : request;
  }

  // https://itnext.io/angular-tutorial-implement-refresh-token-with-httpinterceptor-bfa27b966f57
  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.refreshTokenInProgress) {
      // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
      // – which means the new token is ready and we can retry the request again
      return this.refreshTokenSubject.pipe(
          filter(accessToken => !!accessToken),
          take(1),
          switchMap((accessToken) => next.handle(this.addAccessToken(request, accessToken)))
      );
    } else {
      this.refreshTokenInProgress = true;
      // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
      this.refreshTokenSubject.next(null);

      // Call tokenService.retrieveTokenByRefreshToken (this is an Observable that will be returned)
      return this.tokenService.getRefreshToken().pipe(
        switchMap((refreshToken: string) => {
          if (!refreshToken) {
            return throwError('No refresh token available.');
          }

          return this.tokenService.retrieveTokenByRefreshToken(refreshToken).pipe(
            switchMap((user: User) => {
              // When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              this.refreshTokenInProgress = false;
              this.logger.trace('token refresh successful. user=', user);
              this.store.dispatch(tokenRefreshSuccessResponse({ user }));
              this.refreshTokenSubject.next(user.tokenData.access_token);
              return next.handle(this.addAccessToken(request, user.tokenData.access_token));
            }),
            catchError((error: any) => {
              this.refreshTokenInProgress = false;
              this.store.dispatch(loginFailureResponse({ errors: [error] }));
              this.store.dispatch(logoutAction());
              return throwError(error);
            })
          );
        })
      );
    }
  }

  public openServiceOutageDialog(id = null): void {
    const dialogRef = this.dialog.open(ServiceOutageDialogComponent, {
      disableClose: false,
      data: {
        error_ref: id
      }
    });
    dialogRef.afterClosed().subscribe(result => {});
  }

  private shouldSkipAddingToken(url: string): boolean {
    const skippedUrls = [
      '/lightingpoints/search?',
      '/v2/lightingpoints/search',
      '/controlcabinets/search?',
      '/v2/controlcabinets/search',
      '/lightingpoints?',
      '/v2/lightingpoints',
      '/controlcabinets?',
      '/v2/controlcabinets'
    ];
    return skippedUrls.some(skipUrl => url.includes(skipUrl));
  }
}
