import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from '../../services/env.service';
import * as ReleaseNotes from '../../../../../assets/releasenotes/ReleaseNotes.json';

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss'],
})
export class ReleaseNotesComponent implements OnInit {
  public releases: Release[];
  public expanedPanel: string;
  public supportEmail: string =
    EnvironmentService.getEnvironment().supportEmail;

  constructor() {}

  ngOnInit(): void {
    const rels: Release[] = (ReleaseNotes as any).default;
    this.releases = rels.sort((a, b) =>
      a.ordinal < b.ordinal ? 1 : a.ordinal > b.ordinal ? -1 : 0
    );
  }

  public setExpandedPanel(newId: string, panelId: string): void {
    if (!newId && this.expanedPanel !== panelId) {
      return;
    }
    this.expanedPanel = newId;
  }
}

export class Release {
  id: string;
  ordinal: number;
  name: string;
  date: string;
  detailsLink?: string;
  features?: string[];
  improvements?: string[];
  bugfixes?: string[];
}
