// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'lighton-fe-stage',
  authMode: 'EXTERNAL_MSAD',
  authDemoClientId: '5dlo0vo66vqdv20355me08l27g',
  authMsadClientId: '4qfl0jk5lqntntbfdnjv91n0uv',
  supportEmail: 'support-strassenbeleuchtung@netze-bw.de',
  buildId: '1485023',
  buildNumber: '20240916.1',
  sourceBranchName: '3476921_add-pop-up-message-for-expired-session',
  sourceVersion: '81a7c789cfc683e5a7cd6e57770355430265795f',
  requestedFor: 'Sylwester Feduk',
  pipelineStartTime: '2024-09-16 15:10:29+02:00',
  xApiKey: 'GooRDiKrSj5eevcvjecCnaB3wTaVqllK3ShNF6rd',
  automatedLoginState: '0f4a1483-23a3-4d86-80b1-8cc63a7769af',

  incidentBasePath: 'https://api.dienstleistung-light-on-staging.enbw.cloud',
  incidentService: 'https://api.dienstleistung-light-on-staging.enbw.cloud',

  demoPoolUrl: 'https://dl-light-on-dev-demo.auth.eu-central-1.amazoncognito.com',
  msadPoolUrl: 'https://dl-light-on-staging.auth.eu-central-1.amazoncognito.com',

  lionBasePath: 'https://api.lion-stage.dienstleistungen.netze-bw.de',
  lionService: 'https://api.lion-stage.dienstleistungen.netze-bw.de',

  users: {
    lighton: 'bnmyxcv',
    koerker: '#happy',
    augstej: '#light',
    anselre: '#laterne',
  },
};
