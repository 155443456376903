
<div class="container">
  <div class="page-header">
    <h1 id="releaseNotesCaptureH1" i18n="@@release.note.capture.view.heading1">Release Notes</h1>
  </div>
  <div class="information" style="margin-bottom: 20px;">
    <span i18n="@@release.note.info.text">Nachfolgend finden Sie eine Übersicht über die Releases von DiCon und die jeweils realisierten Features.</span>
    <br>
    <br>
    <span i18n="@@support.hint.contact">Bei Fragen oder Problemen wenden Sie sich bitte an den </span> <a class="support-link" [href]="'mailto:' + supportEmail" i18n="@@support.name">Support</a>.
  </div>
  <mat-accordion>
    <mat-expansion-panel *ngFor="let rel of releases"
      (opened)="setExpandedPanel(rel.id, rel.id)" (closed)="setExpandedPanel(null, rel.id)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon [ngClass]="{ 'expanded': expanedPanel === rel.id, 'closed': expanedPanel !== rel.id }">new_releases</mat-icon>
          <span class="rlspan"><b>{{rel.name}}</b></span>
          <span class="rlspan">{{rel.date}}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <span *ngIf="rel.features">
        <h2>Neue Funktionen</h2>
        <ul class="nd-list nd-list--icon">
          <li *ngFor="let feature of rel.features"><i class="nd-ico-check"></i>{{feature}}</li>
        </ul>
      </span>
      <span *ngIf="rel.improvements">
        <h2>Verbesserungen</h2>
        <ul class="nd-list nd-list--icon">
          <li *ngFor="let improvement of rel.improvements"><i class="nd-ico-check"></i>{{improvement}}</li>
        </ul>
      </span>
      <span *ngIf="rel.bugfixes">
        <h2>Bugfixes</h2>
        <ul class="nd-list nd-list--icon">
          <li *ngFor="let bugfix of rel.bugfixes"><i class="nd-ico-check"></i>{{bugfix}}</li>
        </ul>
      </span>
      <div *ngIf="rel.detailsLink">
        <span i18n="@@release.note.details">Detaillierte Informationen zum Release finden Sie </span> <a class="support-link" [href]="rel.detailsLink" target="_blank" i18n="@@release.note.here">hier</a>.
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
