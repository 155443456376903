import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DialogResult } from '../../models/enums/dialog-result/dialog.result';

@Component({
  selector: 'app-service-outage-dialog',
  templateUrl: './service-outage.dialog.component.html',
  styleUrls: ['./service-outage.dialog.component.scss']
})
export class ServiceOutageDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ServiceOutageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    protected iconRegistry: MatIconRegistry,
  ) {
  }

  onClsBtnClick(): void {
    this.dialogRef.close(DialogResult.CLOSED);
  }
}
