import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ViewMode } from '../../models/enums/view-mode/view-mode';
import { EnvironmentService } from '../../services/env.service';
import { ObjUtils } from '../../services/obj.utils';
import { ErrorModalDialogData } from './error-modal-dialog.data';
import { ErrorModalComponent } from './error-modal.component';

export class ErrorModalDialogDataImpl implements ErrorModalDialogData {
  public static readonly HNT_DEFAULT: string
    = `Bei Fragen oder Problemen wenden Sie sich bitte an den <a class="support-link" href="mailto:${EnvironmentService.getEnvironment().supportEmail}">Support</a>.`;

  private static readonly MSG_DEFAULT: string = 'Etwas ist schief gelaufen!';
  private static readonly BTN_DEFAULT: string = 'Schließen';
  private static readonly MSG_NOT_IMPLEMENTED: string = 'Ooooooppps';
  private static readonly HNT_NOT_IMPLEMENTED: string = 'Diese Funktion ist noch nicht implementiert.';

  public constructor(
    public message: string,
    public hint: string,
    public button?: string,
    public link?: string,
    public viewMode?: ViewMode,
    public fragment?: string) {
  }

  public static getDefaultErrorData(withButton?: boolean): ErrorModalDialogData {
    return new ErrorModalDialogDataImpl(ErrorModalDialogDataImpl.MSG_DEFAULT,
      ErrorModalDialogDataImpl.HNT_DEFAULT, !!withButton? ErrorModalDialogDataImpl.BTN_DEFAULT : null);
  }

  public static getNotImplementedErrorData(withButton?: boolean): ErrorModalDialogData {
    return new ErrorModalDialogDataImpl(ErrorModalDialogDataImpl.MSG_NOT_IMPLEMENTED,
      ErrorModalDialogDataImpl.HNT_NOT_IMPLEMENTED, !!withButton? ErrorModalDialogDataImpl.BTN_DEFAULT : null);
  }

  public static openErrorDialog(dialog: MatDialog, notImplemented?: boolean, msg?: string, hnt?: string): void {
    return ErrorModalDialogDataImpl.openErrorDialogWithButton(dialog, notImplemented, msg, hnt, null, false, null);
  }

  public static openErrorDialogWithButton(dialog: MatDialog, notImplemented?: boolean, msg?: string, hnt?:
    string, btn?: string, withButton?: boolean, lnk?: string, viewMode?: ViewMode, fragment?: string): void {
    const data: ErrorModalDialogData =
      !!notImplemented ? ErrorModalDialogDataImpl.getNotImplementedErrorData(withButton)
        : ErrorModalDialogDataImpl.getDefaultErrorData(withButton);
    if (!ObjUtils.isNullOrUndefinedOrEmpty(msg)) {
      data.message = msg;
    }
    if (!ObjUtils.isNullOrUndefinedOrEmpty(hnt)) {
      data.hint = hnt;
    }
    if (!ObjUtils.isNullOrUndefinedOrEmpty(btn)) {
      data.button = btn;
    }
    if (!ObjUtils.isNullOrUndefinedOrEmpty(lnk)) {
      data.link = lnk;
    }
    if (!ObjUtils.isNullOrUndefined(viewMode)) {
      data.viewMode = viewMode;
    }
    if (!ObjUtils.isNullOrUndefinedOrEmpty(fragment)) {
      data.fragment = fragment;
    }
    dialog.open(ErrorModalComponent, {
      disableClose: true,
      data
    });
  }
}
