import { Component, Inject } from '@angular/core';
import { DialogResult } from '../../models/enums/dialog-result/dialog.result';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'app-session-expired-dialog',
  templateUrl: './session-expired-dialog.component.html',
  styleUrls: ['./session-expired-dialog.component.scss']
})
export class SessionExpiredDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<SessionExpiredDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    protected iconRegistry: MatIconRegistry,
  ) {
  }

  onClsBtnClick(): void {
    this.dialogRef.close(DialogResult.CLOSED);
  }

}
