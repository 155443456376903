import { Injectable } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SpinnerModalComponent } from './spinner-modal.component';
import { SpinnerModalDialogData } from './spinner.modal.dialog.data';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class Spinner {
    public spinner: MatDialogRef<SpinnerModalComponent>;

    constructor() {}

    public show(dialog: MatDialog, data?: SpinnerModalDialogData): void {
      if (!data) {
          data = { message: 'Anwendung lädt...'};
      }
      this.spinner = dialog.open(SpinnerModalComponent, {
          panelClass: 'transparent',
          disableClose: true,
          data,
      });
    }

    public close(): void {
      if (!this.spinner) {
          return;
      }
      this.spinner.close();
      this.spinner = null;
    }

    public spin(observable: Observable<any>, dialog: MatDialog, data?: SpinnerModalDialogData) {
      this.show(dialog, data);
      const subscription = observable.subscribe(
      (response: any) => {
          subscription.unsubscribe();
          // handle response
          this.close();
      },
      (error: any) => {
          subscription.unsubscribe();
          // handle error
          this.close();
        },
        () => {
          this.close();
        }
      );
    }

    public isSpinning() {
      return !!this.spinner;
    }
}
