import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MAT_LEGACY_TABS_CONFIG as MAT_TABS_CONFIG } from '@angular/material/legacy-tabs';
import { DocumentDeleteModalComponent } from './common/components/document-delete-modal/document-delete-modal.component';
import { DocumentUploadModalComponent } from './common/components/document-upload-modal/document-upload-modal.component';
import { DocumentsComponent } from './common/components/documents/documents.component';
import { ErrorModalComponent } from './common/components/error-modal/error-modal.component';
import { SpinnerModalComponent } from './common/components/spinner-modal/spinner-modal.component';
import { SharedRoutingModule } from './shared-routing.module';
import { DocumentFilenameChangeModalComponent } from './common/components/document-filename-change-modal/document-filename-change-modal.component';
import { GoogleMapModalComponent } from './common/components/google-map-modal/google-map-modal.component';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { GoogleMapsModule } from '@angular/google-maps';
import { VersionInfoComponent } from './common/components/version-info/version-info.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ConfirmModalComponent } from './common/components/confirm-modal/confirm-modal.component';
import { ResultModalComponent } from './common/components/result-modal/result-modal.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { ReleaseNotesComponent } from './common/components/release-notes/release-notes.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { UserDataComponent } from './common/components/user-data/user-data.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { NotFoundComponent } from './common/components/not-found/not-found.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { UserAvatarComponent } from './common/components/user-avatar/user-avatar.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { OrderTypeLabelPipe } from './common/pipes/order-type-label.pipe';
import { PriorityLabelPipe } from './common/pipes/priority-label.pipe';
import { RbacShowDirective } from './common/directives/rbac-show.directive';
import { MiddleclickDirective } from './common/directives/middleclick.directive';
import { ServiceOutageDialogComponent } from './common/components/service-outage-dialog/service-outage.dialog.component';
import { MaintenanceDialogComponent } from './common/components/maintenance-dialog/maintenance-dialog.component';
import { DateTimeFormatPipe } from './common/pipes/date-time-format.pipe';
import { SessionExpiredDialogComponent } from './common/components/session-expired-dialog/session-expired-dialog.component';

@NgModule({
    declarations: [
        ErrorModalComponent,
        SpinnerModalComponent,
        DocumentDeleteModalComponent,
        DocumentUploadModalComponent,
        DocumentsComponent,
        DocumentFilenameChangeModalComponent,
        GoogleMapModalComponent,
        VersionInfoComponent,
        ConfirmModalComponent,
        ResultModalComponent,
        ServiceOutageDialogComponent,
        ReleaseNotesComponent,
        UserDataComponent,
        NotFoundComponent,
        UserAvatarComponent,
        OrderTypeLabelPipe,
        PriorityLabelPipe,
        RbacShowDirective,
        MiddleclickDirective,
        MaintenanceDialogComponent,
        DateTimeFormatPipe,
        SessionExpiredDialogComponent,
    ],
    imports: [
        CommonModule,
        SharedRoutingModule,
        HttpClientModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatDialogModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatRadioModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatTooltipModule,
        GoogleMapsModule,
        ClipboardModule,
        MatButtonModule,
        MatMenuModule
    ],
    bootstrap: [],
    exports: [
        ErrorModalComponent,
        SpinnerModalComponent,
        DocumentDeleteModalComponent,
        DocumentUploadModalComponent,
        DocumentsComponent,
        DocumentFilenameChangeModalComponent,
        GoogleMapModalComponent,
        VersionInfoComponent,
        ConfirmModalComponent,
        ResultModalComponent,
        ServiceOutageDialogComponent,
        UserAvatarComponent,
        PriorityLabelPipe,
        OrderTypeLabelPipe,
        RbacShowDirective,
        MiddleclickDirective,
        MaintenanceDialogComponent,
    ],
    providers: [
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'de-DE'
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [
                MAT_DATE_LOCALE
            ]
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'DD.MM.YYYY',
                },
                display: {
                    dateInput: 'DD.MM.YYYY',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'DD.MM.YYYY',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            }
        },
        {
            provide: MAT_TABS_CONFIG,
            useValue: {
                animationDuration: '0ms'
            }
        },
        PriorityLabelPipe
    ]
})
export class SharedModule { }
