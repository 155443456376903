import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogResult } from '../../models/enums/dialog-result/dialog.result';
import { DocumentDeleteModalDialogData } from './document-delete-modal-dialog.data';

@Component({
  selector: 'app-document-delete-modal',
  templateUrl: './document-delete-modal.component.html',
  styleUrls: ['./document-delete-modal.component.scss']
})
export class DocumentDeleteModalComponent {

  constructor(
    public dialogRef: MatDialogRef<DocumentDeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DocumentDeleteModalDialogData,
    protected sanitizer: DomSanitizer,
    protected iconRegistry: MatIconRegistry,
  ) {
    iconRegistry.addSvgIcon('bin', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/bin.svg'));
  }

  onClsBtnClick(): void {
    this.close(DialogResult.CLOSED);
  }

  onConfirmBtnClick(): void {
    this.close(DialogResult.DELETE);
  }

  close(res: DialogResult) {
    this.dialogRef.close(res);
  }
}
