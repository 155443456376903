import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator, MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Incident } from 'app/incidents/models/incident';

import { UploadedDocumentListEntry } from '../../models/uploadedDocumentListEntry';
import { NGXLogger } from 'ngx-logger';
import { forkJoin, Observable, of } from 'rxjs';
import { share, mergeMap } from 'rxjs/operators';
import { DataHoldingService } from '../../services/data-holding-service/data-holding.service';
import { DocService } from '../../services/doc.service';
import { FileUtils } from '../../services/file.utils';
import { DialogResult } from '../../models/enums/dialog-result/dialog.result';
import { DocumentDeleteModalDialogDataImpl } from '../document-delete-modal/document-delete-modal-dialog.data.impl';
import { DocumentDeleteModalComponent } from '../document-delete-modal/document-delete-modal.component';
import { DocumentFilenameChangeModalComponent } from '../document-filename-change-modal/document-filename-change-modal.component';
import { DocumentFilenameChangeModalDataImpl } from '../document-filename-change-modal/document-filename-change-modal.data.impl';
import { DocumentUploadModalComponent } from '../document-upload-modal/document-upload-modal.component';
import { DocumentUploadModalDataImpl } from '../document-upload-modal/document-upload-modal.data.impl';
import { ErrorModalDialogDataImpl } from '../error-modal/error-modal-dialog.data.impl';
import { Spinner } from '../spinner-modal/spinner';
import { WorkMode } from '../../models/enums/work-mode/work.mode';
import { User } from 'app/shared/common/models/user';
import { UserRoleUtils } from '../../services/user.role.utils';
import { UploadedDocumentUtils } from '../../services/uploaded.document.utils';
import { DocUploadService } from '../../services/doc.upload.service';
import { WorkModeUtils } from '../../services/work.mode.utils';
import { AppState } from 'app/app-state';
import { Store } from '@ngrx/store';
import { loggedInUserSelector } from 'app/auth/state/auth.reducers';
import { v4 } from 'uuid';
import {
  DocumentUploadResponseTO,
  IncidentDocumentCollectionTO, IncidentTOV2,
} from 'app/shared/api/generated/v2/incident';

interface SelectedFile {
  name: string;
  size: string;
  file: File;
  key: string;
}

export type DocumentMode = 'INCIDENT' | 'ORDER';

const DocumentMode = {
  INCIDENT: 'INCIDENT' as DocumentMode,
  ORDER: 'ORDER' as DocumentMode,
};

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit, AfterViewInit {
  public displayedColumns = [];
  public dataSource = new MatTableDataSource<any>();
  public UserRoleUtils = UserRoleUtils;

  private paginator: MatPaginator;
  private sort: MatSort;
  @ViewChild(MatPaginator) set pagi(pagi: MatPaginator) {
    if (!pagi) {
      return;
    }
    this.paginator = pagi;
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator._intl = this.matPaginatorIntl;
  }

  @ViewChild(MatSort) set srt(srt: MatSort) {
    if (!srt) {
      return;
    }
    this.sort = srt;
    this.dataSource.sort = this.sort;
  }

  public files: SelectedFile[] = [];
  public fileSizeInMb: string;
  public fileName: string;
  private resultDialogOpen = false;
  private docsLoaded = false;
  max_mib = 100;

  @Input()
  public currentIncident: IncidentTOV2;

  @Input()
  public documents: UntypedFormGroup;

  @Input('workMode')
  public currentWorkMode: WorkMode = WorkMode.CREATE;

  @Input('mode')
  public mode: DocumentMode = DocumentMode.INCIDENT;

  @Input()
  public loggedInUser: User;

  @Input()
  public isWizard = true;

  @Input()
  public isMailOrder = false;

  public constructor(
    public dialog: MatDialog,
    private parentForm: FormGroupDirective,
    private spinner: Spinner,
    private docService: DocService,
    private matPaginatorIntl: MatPaginatorIntl,
    private dataHoldingService: DataHoldingService,
    private docUploadService: DocUploadService,
    private logger: NGXLogger,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.doInit();

    if (this.mode == DocumentMode.INCIDENT) {
      this.displayedColumns = ['docName', 'docUploadTimestamp', 'actions'];
    } else {
      this.displayedColumns = ['docName', 'actions'];
    }

    this.store.select(loggedInUserSelector).subscribe((user: User) => {
      this.loggedInUser = user;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'docUploadTimestamp': {
          return item.getDateSortString();
        }
        default: {
          return item[property];
        }
      }
    };
  }

  public doInit(): void {
    //this.formGroup.addControl('documents', new FormArray([]));
    this.logger.trace('current incident=', this.currentIncident);
    this.logger.trace('current work mode=', this.currentWorkMode);
    this.logger.trace('current isWizard=', this.isWizard);

    // in case there was already a document handling before,
    // load the docs from global service
    this.docsLoaded = this.dataHoldingService.areDocsLoaded();
    if (this.isWorkModeEdit()) {
      this.loadDocuments();
    }
  }

  public setFiles(files: FileList): void {
    //this.files = []
    for (let i = 0; i < files.length; i++) {
      this.files.push({
        name: FileUtils.determineFileName(files[i], 50),
        size: FileUtils.determineFileSizeInMb(files[i], 1),
        file: files[i],
        key:
          v4().substring(0, 6) +
          '/' +
          FileUtils.determineFileName(files[i], 50).replace(/[\s]/gm, ''),
      });
    }
  }

  public clearFile(file: SelectedFile | void = null): void {
    if (!file) this.files = [];
    else this.files = this.files.filter((_) => _ !== file);
  }

  upload(event: Event): void {
    event.preventDefault();
    if (!this.files.length) {
      return;
    }
    this.showSpinner(
      this.files.length > 1
        ? ' Dateien werden hochgeladen...'
        : '1 Datei wird hochgeladen'
    );

    forkJoin(
      this.files.map((file) => {
        if (this.mode == DocumentMode.ORDER) {
          return this.docService
            .generateTempPresignedDocumentUrl(file.name, file.file)
            .pipe(mergeMap((res) => this.performNewUpload(res, file.file)));
        }

        return this.docService
          .generatePresignedDocumentUrl(
            this.determineCurrentIncidentId(),
            file.name,
            file.file
          )
          .pipe(mergeMap((res) => this.performNewUpload(res, file.file)));
      })
    ).subscribe(
      (res) => {
        if (this.mode == DocumentMode.INCIDENT) { this.loadDocuments(true); }
      },
      (error: any) => {
        // case data could not be loaded
        this.logger.log(error);
        this.showUploadResultDialog(false);
      }
    );
  }

  private performNewUpload(
    documentUploadResponseTO: DocumentUploadResponseTO,
    file: File
  ): Observable<any> {
    const expectedHttpStatus: number = 200;

    const uploadObs: Observable<any> = this.docUploadService
      .uploadDocumentForPresignedUrl(
        documentUploadResponseTO.uploadUrl,
        file,
        documentUploadResponseTO
      )
      .pipe(share());
    uploadObs.subscribe(
      (res) => {
        const success: boolean = expectedHttpStatus === res.status;
        if (!success) {
          this.showUploadResultDialog(success);
          return;
        }
        this.closeSpinner();
        this.logger.trace('s3 upload response=', res);
        this.clearFile();
        // Next line is commented, as the objectUrl is not correct from the returning object.
        // A proper reload later loads the correct objectUrl.
        /* this.addToList(UploadedDocumentUtils.convertToObject(documentUploadResponseTO)); */

        if (this.mode == DocumentMode.ORDER) {
          documentUploadResponseTO.fileName = file.name;
        }

        if (this.mode == DocumentMode.ORDER || !this.isWorkModeEdit())
          this.addToList(
            UploadedDocumentUtils.convertToObject(documentUploadResponseTO)
          );
      },
      (error: any) => {
        // case data could not be loaded
        this.logger.log(error);
        this.showUploadResultDialog(false);
      }
    );

    return uploadObs;
  }

  private addToList(elem: UploadedDocumentListEntry): void {
    const items = this.dataSource.data;
    items.push(elem);
    this.setDocuments(items, true);
  }

  private removeFromList(objKey: string): void {
    const items = this.dataSource.data;
    if (!items.length) {
      return;
    }
    const index: number = items.findIndex((elem) => objKey === elem.objectKey);
    items.splice(index, 1);
    this.setDocuments(items, true);
  }

  private replaceInList(
    objKey: string,
    newUploadedDoc: UploadedDocumentListEntry
  ): void {
    this.removeFromList(objKey);
    this.addToList(newUploadedDoc);
  }

  private showUploadResultDialog(success: boolean): void {
    if (!!this.resultDialogOpen) {
      return;
    }
    this.resultDialogOpen = true;
    this.closeSpinner();
    const dialogRef = this.dialog.open(DocumentUploadModalComponent, {
      disableClose: true,
      data: DocumentUploadModalDataImpl.getDefaultUploadModalData(success),
    });
    if (!!dialogRef) {
      dialogRef.afterClosed().subscribe((result) => {
        this.logger.trace('delete dialog closed with result=', result);
        this.resultDialogOpen = false;
      });
    }
  }

  public redirectToDetails(file): void {
    if (!file.objectUrl) {
      this.openErrorDialog();
      return;
    }
    FileUtils.openFileUrlInNewTab(file.objectUrl);
  }

  public redirectToRename(objKey: string, filename: string): void {
    this.openChangeFilenameDialog(objKey, filename);
  }

  public redirectToDelete(objKey: string): void {
    this.openDeleteDialog(objKey);
  }

  public isWorkModeEdit(): boolean {
    return WorkModeUtils.isWorkModeEdit(this.currentWorkMode);
  }

  public isWorkModeClone(): boolean {
    return this.currentWorkMode == WorkMode.COPY;
  }

  public deleteDocumentFromService(objKey: string): void {
    const delObs = this.docService
      .deleteDocument(this.determineCurrentIncidentId(), objKey)
      .pipe(share());
    new Spinner().spin(delObs, this.dialog, {
      message: 'Datei wird gelöscht...',
    });
    delObs.subscribe(
      (response: any) => {
        // case document is deleted successfully
        const succ: boolean = 204 === response.status;
        this.logger.trace(
          `${!!succ ? '' : 'un'}successfully deleted document`,
          response
        );
        if (!!succ) {
          return;
        }
        const notFound: boolean = 404 === response.status;
        this.logger.trace(`document ${!notFound ? '' : 'not'} found`);
      },
      (error: any) => {
        // case document is not deleted
        this.logger.log('failed to delete message', error);
        this.openErrorDialog();
      }
    );
  }

  public loadDocuments(force: boolean = false): void {
    if (force) this.docsLoaded = false;
    if (!this.existsCurrentIncidentWithId() || this.isWorkModeClone()) {
      return;
    }
    if (!!this.docsLoaded) {
      this.setDocuments(this.dataHoldingService.getCurrentDocs(), false);
      return;
    }
    let resArr: UploadedDocumentListEntry[] = [];
    let obsDocs: Observable<IncidentDocumentCollectionTO> = this.docService
      .getDocuments(this.determineCurrentIncidentId())
      .pipe(share());
    this.closeSpinner();
    this.showSpinner('Lädt Dokumente...');
    //new Spinner().spin(obsDocs, this.dialog, { message: 'Lädt Dokumente...' });
    obsDocs.subscribe(
      (docList: IncidentDocumentCollectionTO) => {
        docList.documents.forEach((elem) => {
          resArr.push(UploadedDocumentUtils.convertToObject(elem));
        });
        resArr = resArr.sort((a, b) => {
          return (
            a.getDateSortString().localeCompare(b.getDateSortString()) * -1
          );
        });
        this.setDocuments(resArr, true);
        this.docsLoaded = true;
        this.closeSpinner();
      },
      (error: any) => {
        // case data could not be loaded
        this.logger.log(error);
      }
    );
  }

  private openDeleteDialog(objKey: string): void {
    const dialogRef = this.dialog.open(DocumentDeleteModalComponent, {
      disableClose: true,
      data: DocumentDeleteModalDialogDataImpl.getDefaultDeleteDialogData(
        objKey
      ),
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.logger.trace('delete dialog closed with result=', result);
      if (DialogResult.DELETE !== result) {
        return;
      }
      this.performDelete(objKey);
    });
  }

  private openChangeFilenameDialog(objKey: string, filename: string): void {
    const exFilenames = this.dataSource.data.map((elem) => elem.fileName);
    const dialogRef = this.dialog.open(DocumentFilenameChangeModalComponent, {
      disableClose: true,
      data: DocumentFilenameChangeModalDataImpl.getDefaultFilenameChangeModalData(
        filename,
        exFilenames
      ),
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.logger.trace('change filename dialog closed with result=', result);
      if (DialogResult.MODIFY !== result.dialogResult) {
        return;
      }
      this.performRename(objKey, result.newFilename).subscribe(() =>
        this.loadDocuments(true)
      );
    });
  }

  private performDelete(objKey: string): void {
    if (this.isWorkModeEdit() && !!objKey) {
      this.deleteDocumentFromService(objKey);
    }
    this.removeFromList(objKey);
  }

  private performRename(objKey: string, newFilename: string): Observable<any> {
    if (!this.isWorkModeEdit() || !objKey) {
      return of();
    }
    return this.renameFileViaService(objKey, newFilename);
  }

  private renameFileViaService(
    objKey: string,
    newFilename: string
  ): Observable<any> {
    const rnObs = this.docService
      .changeDocName(this.determineCurrentIncidentId(), objKey, newFilename)
      .pipe(share());
    new Spinner().spin(rnObs, this.dialog, {
      message: 'Datei wird umbenannt...',
    });
    rnObs.subscribe(
      (response: any) => {
        // case document is renamed successfully
        if (200 === response.status) {
          this.replaceInList(
            objKey,
            UploadedDocumentUtils.convertToObject(response.body)
          );
        }
      },
      (error: any) => {
        // case document is not renamed
        this.logger.log('failed to rename file', error);
        this.openErrorDialog(false);
      }
    );
    return rnObs;
  }

  private setDocuments(
    docsArr: UploadedDocumentListEntry[],
    global: boolean
  ): void {
    this.dataSource.data = docsArr;

    let _documents = [];
    if (docsArr.length) {
      docsArr.forEach((elem) => {
        _documents.push(elem);
      });
    }
    this.documents?.get('documents').setValue(_documents);

    if (!!global) {
      this.dataHoldingService.setCurrentDocs(docsArr);
    }
  }

  private determineCurrentIncidentId(): string {
    return this.existsCurrentIncidentWithId()
      ? this.currentIncident.uuid
      : null;
  }

  private existsCurrentIncidentWithId(): boolean {
    return this.isWorkModeClone() ? false : !!this.currentIncident?.uuid;
  }

  private openErrorDialog(notImplemented?: boolean): void {
    ErrorModalDialogDataImpl.openErrorDialog(
      this.dialog,
      notImplemented,
      null,
      null
    );
  }

  private showSpinner(msg: string): void {
    this.spinner.close();
    if (!this.spinner.isSpinning()) {
      this.spinner.show(this.dialog, { message: msg });
    }
  }

  private closeSpinner(): void {
    if (this.spinner.isSpinning()) {
      this.spinner.close();
    }
  }

  onFileDropped(event) {
    this.setFiles(event as FileList);
  }
}
