import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Store } from "@ngrx/store";
import { AppState } from "app/app-state";
import { resetLoginErrorAction } from "app/auth/state/auth.actions";
import { ErrorModalDialogDataImpl } from "app/shared/common/components/error-modal/error-modal-dialog.data.impl";
import { LoginResultModalDialogData } from "./login-result-modal-dialog.data";
import { LoginResultModalComponent } from "./login-result-modal.component";

export class LoginResultModalDialogDataImpl implements LoginResultModalDialogData {

  private static readonly MSG_AUTHENTICATION_FAILED: string = 'Authentifizierung nicht erfolgreich!';
  private static readonly HNT_AUTHENTICATION_FAILED: string = `Der Benutzer konnte nicht für DiCon authentifiziert werden. ${ErrorModalDialogDataImpl.HNT_DEFAULT}`;
  private static readonly MSG_LOGIN_FAILED: string = 'Login nicht erfolgreich!';
  private static readonly HNT_LOGIN_FAILED: string = `Der Benutzername oder das Passwort ist falsch. ${ErrorModalDialogDataImpl.HNT_DEFAULT}`;

  private static readonly BTN_BACK: string = 'Zurück';
  private static readonly LNK_LOGIN: string = '/auth/login';

  constructor(
    public message: string,
    public hint: string,
    public success: boolean,
    public button: string,
    public link: string) {}

  public static openAuthenticationFailedResultDialog(dialog: MatDialog): void {
    const dialogRef = dialog.open(LoginResultModalComponent, {
      disableClose: true,
      data: {
        message: LoginResultModalDialogDataImpl.MSG_AUTHENTICATION_FAILED,
        hint: LoginResultModalDialogDataImpl.HNT_AUTHENTICATION_FAILED,
        button: LoginResultModalDialogDataImpl.BTN_BACK,
        link: LoginResultModalDialogDataImpl.LNK_LOGIN,
        success: false,
      }
    });
  }

  public static openLoginFailedResultDialog(dialog: MatDialog, store: Store<AppState>): void {
    const dialogRef = dialog.open(LoginResultModalComponent, {
      disableClose: true,
      data: {
        message: LoginResultModalDialogDataImpl.MSG_LOGIN_FAILED,
        hint: LoginResultModalDialogDataImpl.HNT_LOGIN_FAILED,
        button: LoginResultModalDialogDataImpl.BTN_BACK,
        link: LoginResultModalDialogDataImpl.LNK_LOGIN,
        success: false,
      }
    });
    dialogRef.afterOpened().subscribe((data: any) => {
      store.dispatch(resetLoginErrorAction());
    });
  }
}
