<div class="cls-div">
  <button id="clsBtn" class="cls-btn" mat-icon-button (click)="onClsBtnClick()">
      <mat-icon style="margin: 0px;">close</mat-icon>
  </button>
</div>
<h1 id="message" mat-dialog-title>
  <mat-icon style="font-size: 28px;float: left;margin-right: 10px;">info</mat-icon>
  Teilweiser Service Ausfall
</h1>
<div mat-dialog-content>
  <div id="hint" class="information">
      Wir erleben einen Service-Ausfall. Bei Fragen kontaktieren Sie bitte Ihren Ansprechpartner.<br>
      <span *ngIf="data.error_ref">Error-Reference: {{data.error_ref}}</span>
  </div>
</div>
